/* Medias */ 


/*--- CONTAINER OTHERS THEMES ---*/
@media (max-width: 1300px){
	#content{
		.container_result{
			.container_otherThemes{			
				.container_themes{		
					width: 1139px!important;

					.block_theme{
						.content_theme{
							.bg_theme{
								width: 320px!important;
								height: 230px!important;
							}
						}
					}
				}
				
			}
		}
	}
}

@media (min-width: 1011px) and (max-width: 1160px){
	#content{
		.container_result{
			.container_otherThemes{			
				.container_themes{		
					width: 990px!important;

					.block_theme{
						.content_theme{
							.bg_theme{
								width: 270px!important;
								height: 200px!important;
							}
						}
					}
				}
				
			}
		}
	}
}

@media (min-width: 676px) and (max-width: 1010px){
	#content{
		.container_result{
			.container_otherThemes{			
				.container_themes{		
					width: 658px!important;

					.block_theme{
						.content_theme{
							.bg_theme{
								width: 270px!important;
								height: 200px!important;
							}
						}
					}
				}
				
			}
		}
	}
}

@media (min-width: 640px) and (max-width: 675px){
	#content{
		.container_result{
			.container_otherThemes{			
				.container_themes{		
					width: 623px!important;

					.block_theme{
						.content_theme{
							.bg_theme{
								width: 567px!important;
								height: 423px!important;
							}
						}
					}
				}
				
			}
		}
	}
}

@media (max-width: 639px){
	#content{
		.container_result{
			.container_otherThemes{			
				.container_themes{		
					width: 316px!important;

					.block_theme{
						.content_theme{
							.bg_theme{
								width: 260px!important;
								height: 185px!important;
							}
						}
					}
				}
				
			}
		}
	}
}

/*--- END OTHERS THEMES ---*/

@media (min-width: 1061px) and (max-width: 1160px){
	#content{
		.container_result{

			.container_details_theme{
                            
                                .content_list{
                                    width: 100%!important;
                                    .list_results{
                                        margin-left: 80px!important;
                                    }
                                }
				.container_desc_theme{
					.first_part_desc{
						margin-left:53px!important;
					}
				}
			}
		}
	}
}

@media (max-width: 1060px){
	#content{
		.container_result{
                    .container_details_theme{
			.container_desc_theme{
				.first_part_desc,
				.second_part_desc{
					width: 100%!important;
					margin-left: 0!important;
					padding: 0 10px;
					box-sizing: border-box;

					
					.img_theme{
						img{
							width: 100%;
						}
					}
					
					ul{
						width: 100%!important;
					}
		
					.iframe_result_theme{
						//margin-top: 32px!important;

						.text_iframe{
							//width: 100%!important;
						}
					}

					.change_banner_style{
						width: 100%!important;
						margin-top: 39px !important;

						label,
						select{
							display: block;
							width: 100%!important;
						}
					}
				}

				.second_part_desc{
					margin-top: 48px;
				}
			}
                    }
		}
	}
}

@media (min-width: 396px) and (max-width: 1060px){
	#content{
		.container_result{
                    .container_details_theme{
                            
                        .content_list{
                            width: 363px!important;
                            .list_results{
                                margin-left: 0!important;
                            }
                        }
                    }
                }
        }
}

@media (min-width: 611px) and (max-width: 1060px){
	#content{
		.container_result{
			.container_desc_theme{
                            
                            .info_themeForest{
                                width: 610px!important;
                                padding-left: 0!important;
                            }
				.first_part_desc,
				.second_part_desc{
					width: 591px!important;

					.template_responsive{
						//left: 244px!important;
					}

					.desc_results{
						//height: 154px;

                                                .result_red,
                                                .result_100,
                                                .result_orange,
                                                .result_green{
							transform: scale(1.2)!important;
							//float: none!important;
							//margin-top: 50px!important;
							margin-left: 8px;
						}
						
						.content_desc_results{
							//width: 430px;
							//float: right;
							padding: 12px 0 9px 0!important;
							
							.img_separt{
								//margin: 0 12px!important;
								//margin-top: 5px!important;
								img{
                                                                    //height: 111px;  // à ressortir
								}
							}
							.title_desc_results{
								width: 257px;
								font-size: 28px!important;

								.desc_open_source{
									font-size: 16px!important;
								}
							}

							.view_detail{
								width: 382px !important;
								height: 49px !important;
								padding-top: 13px!important;
								margin-top: 20px;

								.viewDetail{
									font-size: 16px!important;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1200px){
	#content{
		.container_result{
                    
                    .container_desc_theme{
                        
			.container_critical,
			.container_warning,
			.container_tipOff{

				.descript_alert{
				width: 100%!important;

					ol{
						padding-right: 15px;
						padding-left: 40px;
					}
				}
			}
                    }
                        
                    
		}
	}
} 

@media (min-width: 485px) and (max-width: 910px){
	#content{
		.container_result{
			.intro_result{
				.content_intro{
					width: 480px;
				}
			}
		}
	}
}

@media (max-width: 768px){
	#content{
		.container_result{
                    .container_details_theme{
                        .container_otherThemes{
                            .container_themes{
                                .block_theme{
                                    .footer_theme{
                                        .info_theme{
                                            width: 110px!important;
                                        }
                                    }
                                }
                                
                            }
                            
                        }
                        
                    }
		}
	}
}

@media (max-width: 610px){
	#content{
		.container_result{

			.intro_result{
				.content_intro{

					h1{
						line-height: 100%;
					}

					.container_alerts{

						.warnings_alerts{
							margin-top: 15px;
						}
					}
				}
			}
			.container_details_theme{
				.container_desc_theme{
					width: 290px;
					margin-right: auto;
					margin-left: auto;
                                        
                                        .info_themeForest{
                                            width: 100%!important;
                                            padding: 0 10px!important;
                                        }

					.first_part_desc{

						.template_responsive{
							top: 23px!important;
						}

						.img_theme{

							img{
								width: 100%;
							}
						}

						.desc_results{
						

							.result_red{
								margin-left: 15px;
								margin-top: 15px!important;
							}
							
							.content_desc_results{
								.view_detail{
									margin-left: 4px;
									margin-top: 15px;
								}
							}
						}
					}

					.second_part_desc{

						ul{
							.first_line,
							.second_line{

								.second_col{
									width: 100%!important;
								}
							}

						}
					
					}				
				}
			}

			.container_otherThemes{
				.container_themes{
					.block_theme{
						.content_theme{
							.footer_theme{
								    padding: 13px 5px 11px 19px!important;
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 391px) and (max-width: 610px){
	#content{
		.container_result{
			.container_details_theme{
				.container_desc_theme{
					.first_part_desc{
						.template_responsive{
							left: 142px!important;
						}

					}

				}
			}
		}
	}
}

@media (max-width: 390px){
    
    #content{
        .container_result{
            .container_details_theme{
                
                .content_list{
                    width: 100%!important;
                    padding: 0 10px;
                    .list_results{
                        margin-left: 0!important;
                        
                        li{
                            //margin-top: 5px;
                            margin-left: 7px;
                            
                            &.active{
                                margin-left: 0;
                            }
                        }
                    }
      
                }
                .container_desc_theme{
                    .first_part_desc{
                        .template_responsive{
                           left: 110px!important;
                        }
                        
                        .img_theme
                        {
                            img{
                                width: 300px!important;
                            }
                        }
                        .desc_results{
                            width: 100%!important;
                            .iframe_result_theme{
                                .text_iframe{
                                    width: 215px!important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
