@mixin RobotoTh{
  font-family : 'Roboto', Arial, Helvetica, sans-serif;
  font-weight:100;
}
@mixin RobotoLt{
  font-family : 'Roboto', Arial, Helvetica, sans-serif;
  font-weight:300;
}

@mixin RobotoBd{
  font-family : 'Roboto', Arial, Helvetica, sans-serif;
  font-weight:700;
}

@mixin Lucida{
  font-family : 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva, Verdana, sans-serif; 
  font-weight: normal;
}
@mixin LucidaBd{
  font-family : 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva, Verdana, sans-serif;
  font-weight: bold;
}

@mixin easeInOutCirc($time){
    -webkit-transition: all $time cubic-bezier(0.785, 0.135, 0.150, 0.860); 
    -moz-transition: all $time cubic-bezier(0.785, 0.135, 0.150, 0.860); 
    -o-transition: all $time cubic-bezier(0.785, 0.135, 0.150, 0.860); 
    transition: all $time cubic-bezier(0.785, 0.135, 0.150, 0.860); /* easeInOutCirc */

    -webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860); 
    -moz-transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860); 
    -o-transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860); 
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860); /* easeInOutCirc */
}