// --- RESETS
    button,
    input {
        line-height: normal;

        &:focus{
            outline: 0 none;
        }
    }

    button,
    html input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        -webkit-appearance: button;
        cursor: pointer;
    }

    button[disabled],
    input[disabled] {
        cursor: default;
    }

    input[type="checkbox"],
    input[type="radio"] {
        box-sizing: border-box;
        padding: 0;
    }

    input[type="search"] {
        -webkit-appearance: textfield;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
    }

    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    
    input[type="text"],
    input[type="search"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    textarea,
    select{
        // SUPPRIME LE FOND JAUNE DE CHROME
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            -webkit-text-fill-color: #9f9f9f;
        }
    }


// COULEUR PLACEHOLDER
    ::-webkit-input-placeholder { color: #9f9f9f; opacity: 1!important; }
    :-moz-placeholder { color: #9f9f9f; opacity: 1;}
    ::-moz-placeholder {color: #9f9f9f; opacity: 1;}
    :-ms-input-placeholder {color: #9f9f9f;}

    .error::-webkit-input-placeholder { color: $redLight; opacity: 1!important; }
    .error:-moz-placeholder { color: $redLight; opacity: 1;}
    .error::-moz-placeholder {color: $redLight; opacity: 1;}
    .error:-ms-input-placeholder {color: $redLight;}